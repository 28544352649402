<template>
    <div class="eAppModule eAppBusinessAddressEdit">
        <h3>Business Address</h3>

        <div class="EditingFormError" style="display: none;">

        </div>

        <div class="eAppForm grid-margin-x grid-x grid-margin-y">
            <div class="formRow cell">
                <span class="req">*</span>
                <label id="BusinessStreetAddress_lb" for="BusinessStreetAddress_txtText">Street Address:</label>
                <input name="BusinessStreetAddress_txtText" v-model="businessInfo.businessStreetAddress" :disabled="isDisabled" type="text" maxlength="600" id="BusinessStreetAddress_txtText" class="form-control" autocomplete="off" @input="invalidateBusiness()">
                <div id="businessStreetAddressError" class="error">Please provide a valid Ohio street address.</div>
            </div>
            <div class="formRow cell medium-8">
                <span class="req">*</span>
                <label id="BusinessCity_lb" for="BusinessCity_txtText">City:</label>
                <input name="BusinessCity_txtText" v-model="businessInfo.businessCity" type="text" maxlength="300" :disabled="isDisabled" id="BusinessCity_txtText" autocomplete="off" @input="invalidateBusiness()">
                <div id="businessCityError" class="error">Please provide a valid Ohio city.</div>
            </div>
            <div class="formRow cell medium-4">
                <span class="req">*</span>
                <label id="BusinessState_lb" for="BusinessState_dropDownList">State:</label>
                <select name="BusinessState_dropDownList" v-model="businessInfo.businessState" id="BusinessState_dropDownList" :disabled="isDisabled" class="DropDownField form-control" @change="handleState">
                    <option value="null">Select...</option>
                    <option value="AL"> AL</option>
                    <option value="AK"> AK</option>
                    <option value="AZ"> AZ</option>
                    <option value="AR"> AR</option>
                    <option value="CA"> CA</option>
                    <option value="CO"> CO</option>
                    <option value="CT"> CT</option>
                    <option value="DE"> DE</option>
                    <option value="FL"> FL</option>
                    <option value="GA"> GA</option>
                    <option value="HI"> HI</option>
                    <option value="ID"> ID</option>
                    <option value="IL"> IL</option>
                    <option value="IN"> IN</option>
                    <option value="IA"> IA</option>
                    <option value="KS"> KS</option>
                    <option value="KY"> KY</option>
                    <option value="LA"> LA</option>
                    <option value="ME"> ME</option>
                    <option value="MD"> MD</option>
                    <option value="MA"> MA</option>
                    <option value="MI"> MI</option>
                    <option value="MN"> MN</option>
                    <option value="MS"> MS</option>
                    <option value="MO"> MO</option>
                    <option value="MT"> MT</option>
                    <option value="NE"> NE</option>
                    <option value="NV"> NV</option>
                    <option value="NH"> NH</option>
                    <option value="NJ"> NJ</option>
                    <option value="NM"> NM</option>
                    <option value="NY"> NY</option>
                    <option value="NC"> NC</option>
                    <option value="ND"> ND</option>
                    <option selected value="OH"> OH</option>
                    <option value="OK"> OK</option>
                    <option value="OR"> OR</option>
                    <option value="PA"> PA</option>
                    <option value="RI"> RI</option>
                    <option value="SC"> SC</option>
                    <option value="SD"> SD</option>
                    <option value="TN"> TN</option>
                    <option value="TX"> TX</option>
                    <option value="UT"> UT</option>
                    <option value="VT"> VT</option>
                    <option value="VA"> VA</option>
                    <option value="WA"> WA</option>
                    <option value="DC"> DC</option>
                    <option value="WV"> WV</option>
                    <option value="WI"> WI</option>
                    <option value="WY"> WY</option>
                    <option value="">Province</option>
                    <option value="AB"> AB</option>
                    <option value="BC"> BC</option>
                    <option value="MB"> MB</option>
                    <option value="NB"> NB</option>
                    <option value="NL"> NL</option>
                    <option value="NS"> NS</option>
                    <option value="NT"> NT</option>
                    <option value="NU"> NU</option>
                    <option value="ON"> ON</option>
                    <option value="PE"> PE</option>
                    <option value="QC"> QC</option>
                    <option value="SK"> SK</option>
                    <option value="YT"> YT</option>
                </select>
                <div id="businessStateError" class="error">Please provide a valid state.</div>
            </div>
            <div class="formRow zipFields validateZip cell medium-8">
                <span class="req">*</span>
                <label id="BusinessZip_lb" for="BusinessZip_txtText">Zip:</label>
                <input name="BusinessZip_txtText" v-model="businessInfo.businessZip" type="text" maxlength="5" :disabled="isDisabled" id="BusinessZip_txtText" class="zipFive" autocomplete="off" @input="invalidateBusiness()">
                <input name="$BusinessZipPlus4_txtText" v-model="businessInfo.businessZipPlus4" type="text" :disabled="isDisabled" maxlength="4" id="BusinessZipPlus4_txtText" class="zipFour" autocomplete="off" @input="invalidateBusiness()">
                <a href="https://tools.usps.com/go/ZipLookupAction!input.action" target="_blank" class="eAppZip4Link">Zip +4 Lookup</a>
                <div id="businessZipCodeError" v-if="!validZip" class="zipError error">Please provide a valid Ohio Zip Code.</div>
            </div>
            <div class="formRow cell medium-4">
                <span class="req">*</span>
                <label id="BusinessCounty_lb" for="BusinessCounty_dropDownList">County:</label>
                <select name="BusinessCounty_dropDownList" v-model="businessInfo.businessCounty" :disabled="isDisabled || businessInfo.businessState !== 'OH'" id="BusinessCounty_dropDownList" class="DropDownField">
                    <option value="">Select...</option>
                    <option value="39">ADAMS</option>
                    <option value="11">ALLEN</option>
                    <option value="01">ASHLAND</option>
                    <option value="02">ASHTABULA</option>
                    <option value="65">ATHENS</option>
                    <option value="28">AUGLAIZE</option>
                    <option value="66">BELMONT</option>
                    <option value="40">BROWN</option>
                    <option value="41">BUTLER</option>
                    <option value="77">CARROLL</option>
                    <option value="29">CHAMPAIGN</option>
                    <option value="30">CLARK</option>
                    <option value="42">CLERMONT</option>
                    <option value="43">CLINTON</option>
                    <option value="78">COLUMBIANA</option>
                    <option value="49">COSHOCTON</option>
                    <option value="12">CRAWFORD</option>
                    <option value="03">CUYAHOGA</option>
                    <option value="31">DARKE</option>
                    <option value="13">DEFIANCE</option>
                    <option value="50">DELAWARE</option>
                    <option value="04">ERIE</option>
                    <option value="51">FAIRFIELD</option>
                    <option value="52">FAYETTE</option>
                    <option value="53">FRANKLIN</option>
                    <option value="14">FULTON</option>
                    <option value="67">GALLIA</option>
                    <option value="05">GEAUGA</option>
                    <option value="32">GREENE</option>
                    <option value="68">GUERNSEY</option>
                    <option value="44">HAMILTON</option>
                    <option value="15">HANCOCK</option>
                    <option value="16">HARDIN</option>
                    <option value="79">HARRISON</option>
                    <option value="17">HENRY</option>
                    <option value="45">HIGHLAND</option>
                    <option value="54">HOCKING</option>
                    <option value="80">HOLMES</option>
                    <option value="06">HURON</option>
                    <option value="69">JACKSON</option>
                    <option value="81">JEFFERSON</option>
                    <option value="55">KNOX</option>
                    <option value="07">LAKE</option>
                    <option value="70">LAWRENCE</option>
                    <option value="56">LICKING</option>
                    <option value="33">LOGAN</option>
                    <option value="08">LORAIN</option>
                    <option value="18">LUCAS</option>
                    <option value="57">MADISON</option>
                    <option value="82">MAHONING</option>
                    <option value="58">MARION</option>
                    <option value="09">MEDINA</option>
                    <option value="71">MEIGS</option>
                    <option value="34">MERCER</option>
                    <option value="35">MIAMI</option>
                    <option value="72">MONROE</option>
                    <option value="36">MONTGOMERY</option>
                    <option value="73">MORGAN</option>
                    <option value="59">MORROW</option>
                    <option value="60">MUSKINGUM</option>
                    <option value="74">NOBLE</option>
                    <option value="19">OTTAWA</option>
                    <option value="20">PAULDING</option>
                    <option value="61">PERRY</option>
                    <option value="62">PICKAWAY</option>
                    <option value="47">PIKE</option>
                    <option value="83">PORTAGE</option>
                    <option value="37">PREBLE</option>
                    <option value="21">PUTNAM</option>
                    <option value="10">RICHLAND</option>
                    <option value="63">ROSS</option>
                    <option value="22">SANDUSKY</option>
                    <option value="48">SCIOTO</option>
                    <option value="23">SENECA</option>
                    <option value="38">SHELBY</option>
                    <option value="84">STARK</option>
                    <option value="85">SUMMIT</option>
                    <option value="86">TRUMBULL</option>
                    <option value="87">TUSCARAWAS</option>
                    <option value="64">UNION</option>
                    <option value="24">VAN WERT</option>
                    <option value="75">VINTON</option>
                    <option value="46">WARREN</option>
                    <option value="76">WASHINGTON</option>
                    <option value="88">WAYNE</option>
                    <option value="25">WILLIAMS</option>
                    <option value="26">WOOD</option>
                    <option value="27">WYANDOT</option>
                </select>
                <div id="businessCountyError" class="error">Please provide a valid Ohio county.</div>
                <div class="formRow cell">
                    <input type="button" class="button btn btn-primary" value="Verify Business Address" @click="$refs.uspsValidate.validate('business')" v-if="businessAddValid === false">
                </div>
                <div class="error" v-if="businessAddValid === false">Please Verify your Business Address</div>
            </div>
            <div class="formRow cell dividerRow"> </div>


            <div class="formRow checkboxRow">
                <h5>Mailing Address</h5>
                <div class="CheckBoxField checkbox">
                    <input id="MailingSameAsBusiness_checkbox" :disabled="isDisabled" v-model="businessInfo.mailingSameAsBusiness" @click="handleMailingAddress" type="checkbox" name="MailingSameAsBusiness_checkbox">
                    <label id="MailingSameAsBusiness_lb" for="MailingSameAsBusiness_checkbox">Mailing address is the same as the street address</label>
                </div>
            </div>

            <div class="mailingAddressForm grid-x grid-margin-x grid-margin-y" v-if="!businessInfo.mailingSameAsBusiness">

                <div class="formRow cell">
                    <label id="MailingName_lb" for="MailingName_txtText">Mailing Name:</label>
                    <input name="MailingName_txtText" :disabled="isDisabled" v-model="businessInfo.mailingName" type="text" maxlength="200" id="MailingName_txtText" autocomplete="off" @input="invalidateMailing()">
                    <div id="mailingNameError" class="error" v-if="errorName">Please provide a mailing name.</div>
                </div>
                <div class="formRow cell">
                    <label id="MailingStreetAddress_lb" for="MailingStreetAddress_txtText">Mailing Address:</label>
                    <input name="MailingStreetAddress_txtText" :disabled="isDisabled" v-model="businessInfo.mailingStreetAddress" type="text" maxlength="300" id="MailingStreetAddress_txtText" autocomplete="off" @input="invalidateMailing()">
                    <div id="mailingStreetAddressError" class="error" v-if="errorAddress">Please provide a valid street address.</div>
                </div>
                <div class="formRow cell medium-8">
                    <label id="MailingCity_lb" for="MailingCity_txtText">City:</label>
                    <input name="MailingCity_txtText" :disabled="isDisabled" v-model="businessInfo.mailingCity" type="text" maxlength="200" id="MailingCity_txtText" autocomplete="off" @input="invalidateMailing()">
                    <div id="mailingCityError" class="error" v-if="errorCity">Please provide a valid city.</div>
                </div>
                <div class="formRow cell medium-4">
                    <label id="MailingState_lb" for="MailingState_dropDownList">State:</label>
                    <select name="MailingState_dropDownList" :disabled="isDisabled" v-model="businessInfo.mailingState" id="MailingState_dropDownList" class="DropDownField" @change="invalidateMailing()">
                        <option value="null">Select...</option>
                        <option value="AL"> AL</option>
                        <option value="AK"> AK</option>
                        <option value="AZ"> AZ</option>
                        <option value="AR"> AR</option>
                        <option value="CA"> CA</option>
                        <option value="CO"> CO</option>
                        <option value="CT"> CT</option>
                        <option value="DE"> DE</option>
                        <option value="FL"> FL</option>
                        <option value="GA"> GA</option>
                        <option value="HI"> HI</option>
                        <option value="ID"> ID</option>
                        <option value="IL"> IL</option>
                        <option value="IN"> IN</option>
                        <option value="IA"> IA</option>
                        <option value="KS"> KS</option>
                        <option value="KY"> KY</option>
                        <option value="LA"> LA</option>
                        <option value="ME"> ME</option>
                        <option value="MD"> MD</option>
                        <option value="MA"> MA</option>
                        <option value="MI"> MI</option>
                        <option value="MN"> MN</option>
                        <option value="MS"> MS</option>
                        <option value="MO"> MO</option>
                        <option value="MT"> MT</option>
                        <option value="NE"> NE</option>
                        <option value="NV"> NV</option>
                        <option value="NH"> NH</option>
                        <option value="NJ"> NJ</option>
                        <option value="NM"> NM</option>
                        <option value="NY"> NY</option>
                        <option value="NC"> NC</option>
                        <option value="ND"> ND</option>
                        <option value="OH"> OH</option>
                        <option value="OK"> OK</option>
                        <option value="OR"> OR</option>
                        <option value="PA"> PA</option>
                        <option value="RI"> RI</option>
                        <option value="SC"> SC</option>
                        <option value="SD"> SD</option>
                        <option value="TN"> TN</option>
                        <option value="TX"> TX</option>
                        <option value="UT"> UT</option>
                        <option value="VT"> VT</option>
                        <option value="VA"> VA</option>
                        <option value="WA"> WA</option>
                        <option value="DC"> DC</option>
                        <option value="WV"> WV</option>
                        <option value="WI"> WI</option>
                        <option value="WY"> WY</option>

                    </select>
                    <div id="mailingStateError" class="error" v-if="errorState">Please provide a valid state.</div>
                </div>
                <div class="formRow zipFields cell">
                    <label id="MailingZip_lb" for="MailingZip_txtText">Zip:</label>
                    <input name="MailingZip_txtText" :disabled="isDisabled" v-model="businessInfo.mailingZip" type="text" maxlength="5" id="MailingZip_txtText" autocomplete="off" @input="invalidateMailing()">
                    <input name="MailingZipPlus4_txtText" :disabled="isDisabled" v-model="businessInfo.mailingZipPlus4" type="text" maxlength="4" id="MailingZipPlus4_txtText" autocomplete="off" @input="invalidateMailing()">
                    <div id="mailingZipError" v-if="!validMailZip" class="error">Please provide a valid zip code.</div>
                </div>
                <div class="formRow cell">
                    <input type="button" class="button btn btn-primary" value="Verify Mailing Address"  @click="$refs.uspsValidate.validate('mailing')" v-if="mailingAddValid === false">
                </div>
            </div>
        </div>
        <span class="error" v-if="specialError === true">Please provide standard English text characters only.</span>
        <div class="error" v-if="mailingAddValid === false">Please Verify your Mailing Address</div>
        <div class="btn-actions">
            <input type="button" name="" @click="onSaveClick('back')" value="Save &amp; Back" class=" btn-primary btnBack" title="Save the document and return to previous page" :disabled="businessAddValid === false || mailingAddValid === false">
            <input type="button" name="" @click="onSaveClick('next')" value="Save &amp; Next" class=" btn-primary btnNext" title="Save the document and proceed to next page" :disabled="businessAddValid === false || mailingAddValid === false">
        </div>
        <USPSAddressCheckVue :mailingAddress="streetAddress" :city="city" :state="state" :zipcode="zip" ref="uspsValidate" :title="title"></USPSAddressCheckVue>
    </div>
</template>
<script>

    import $ from 'jquery';
    import USPSAddressCheckVue from '../../../Shared/USPS-AddressCheck.vue';

    export default {
        components: {
            USPSAddressCheckVue
        },
        props: {
            businessInfoJson: String,
            readOnly:Boolean
        },
        data() {
            return {
                businessInfo: {},
                streetAddress: '',
                city: '',
                state: '',
                zip: '',
                isDisabled: false,
                validZip: true,
                validMailZip: true,
                errorName: false,
                errorAddress: false,
                errorCity: false,
                errorState: false,
                specialError: false,
                direction: '',
                mainAddressValid: false,
                title: '',
                validated: false,
                businessAddValid: true,
                mailingAddValid: true
            }
        },
        mounted: function () {
            $('#businessStreetAddressError').hide();
            $('#businessZipCodeError').hide();
            $('#businessCityError').hide();
            $('#businessCountyError').hide();
            $('#businessStateError').hide();
            $('#mailingZipError').hide();

            this.businessInfo = JSON.parse(this.businessInfoJson);

            this.isDisabled = this.readOnly;
        },
        methods: {
            invalidateBusiness() {
                this.title = 'Business Address';
                this.streetAddress = this.businessInfo.businessStreetAddress;
                this.city = this.businessInfo.businessCity;
                this.state = this.businessInfo.businessState;
                this.zip = this.businessInfo.businessZip + this.businessInfo.businessZipPlus4;

                this.businessAddValid = false;
            },
            invalidateMailing() {
                this.title = 'Mailing Address';
                this.streetAddress = this.businessInfo.mailingStreetAddress;
                this.city = this.businessInfo.mailingCity;
                this.state = this.businessInfo.mailingState;
                this.zip = this.businessInfo.mailingZip + this.businessInfo.mailingZipPlus4;

                this.mailingAddValid = false;
            },
            onClickValidateAddress(dir) {
                this.mainAddressValid = false;
                this.$refs.uspsValidate.validate(dir);
            },
            onSaveClick(dir) {
                this.validate();
                // console.log(this.validZip, this.validMailZip);
                if (this.validZip && this.validMailZip && this.specialError === false) {
                    let request = {};
                    request.dir = dir;
                    request.businessInformation = this.businessInfo;
                    this.$emit('onSaveClick', request);
                }
            },
            handleState() {
                 this.updateBusinessInfo(1);
                console.log(this.businessInfo.businessCounty);
                if (this.businessInfo.businessState !== 'OH') {
                    this.businessInfo.businessCounty = "";
                }

            },
            handleMailingAddress() {
                let checkbox = $('#MailingSameAsBusiness_checkbox')[0].checked;

                console.log(checkbox)

                if (checkbox === false) {
                    this.mailingAddValid = false;
                    this.validated = false;
                } else {
                    this.mailingAddValid = true;
                    if (this.businessAddValid === true) {
                        this.validated = true;
                    } else {
                        this.validated = false;
                    }
                }
            },
            validate() {
                if (!this.zipValidation(this.businessInfo.businessZip) || !this.zipPlusFourValidation(this.businessInfo.businessZipPlus4)) {
                    this.validZip = false;
                }
                else {
                    this.validZip = true;
                }
                if (!this.businessInfo.mailingSameAsBusiness && !this.zipValidation(this.businessInfo.mailingZip) || !this.businessInfo.mailingSameAsBusiness && !this.zipPlusFourValidation(this.businessInfo.mailingZipPlus4)) {
                    this.validMailZip = false;
                }
                else {
                    this.validMailZip = true;
                }

                // Name Validation
                if (this.businessInfo.mailingName === null) {
                    this.errorName = true;
                } else {
                    this.errorName = false;
                }

                // Address Validation
                if (this.businessInfo.mailingStreetAddress === null) {
                    this.errorAddress = true;
                } else {
                    this.errorAddress = false;
                }

                // City Validation
                if (this.businessInfo.mailingCity === null) {
                    this.errorCity = true;
                } else {
                    this.errorCity = false;
                }

                // State Validation
                if (this.businessInfo.mailingState === null || this.businessInfo.mailingState === undefined) {
                    this.errorState = true;
                } else {
                    this.errorState = false;
                }

                if (this.validateSpecial($('input[type=text]')) === false) {
                    this.specialError = true;
                } else {
                    this.specialError = false;
                }
            },
            uspsOpen() {
                $('#USPSmodal').foundation('open');
            },
            uspsClose() {
                $('#USPSmodal').foundation('close');
            },
            uspsVerified(msg) {
                if (msg.passthrough === 'business') {
                    this.businessInfo.businessStreetAddress = msg.mailingAddress;
                    this.businessInfo.businessCity = msg.city;
                    this.businessInfo.businessState = msg.state;
                    this.businessInfo.businessZip = msg.zip;

                    this.businessAddValid = true;

                    this.uspsClose();
                } else {
                    this.businessInfo.mailingStreetAddress = msg.mailingAddress;
                    this.businessInfo.mailingCity = msg.city;
                    this.businessInfo.mailingState = msg.state;
                    this.businessInfo.mailingZip = msg.zip;

                    this.mailingAddValid = true;

                    this.uspsClose();
                }

                if (this.businessAddValid === true && this.mailingAddValid === true) {
                    this.validated = true;
                }
            }
        }
    }
</script>