<template>
    <div>
        <span v-if="formSuccess && !isUserLogged">
            <h1>You're almost done!</h1>
            <p>We've sent a confirmation email to you from the address "{{ this.userName }}". Just select the link within that email, and your registration will be complete! Go on, we'll wait here.</p>
        </span>
        <span v-if="isUserLogged && formEnable">
                <img src="/getattachment/44adc819-4717-4eca-8187-a98e2e34cdc7/logo-MLR-242x150.png" alt="" class="fr-fic fr-dii logo_MLR">
                <h1>Register</h1>
                <h3>
                    Create your MyLotto Rewards account
                </h3>
            <p>Congratulations, you are a My Lotto Rewards member! <a href="/mylotto-rewards/redemption-central/">Start enjoying your benefits now!</a></p>
        </span>
    <!-- <div class="contentRow">
        <div class="pageTitle" v-if="step === 0">
            <span v-if="formEnable">
                <img src="/getattachment/44adc819-4717-4eca-8187-a98e2e34cdc7/logo-MLR-242x150.png" alt="" class="fr-fic fr-dii logo_MLR">
                <h1>Register</h1>
                <h3>
                    Create your MyLotto Rewards account
                </h3>
                <p v-if="!isUserLogged">
                    Only members can enjoy the benefits of MyLotto Rewards. Fill out the form below and start earning points today!
                </p>
                
                <p v-if="isUserLogged">
                    Congratulations, you are a My Lotto Rewards member! <a href="/mylotto-rewards/redemption-central/">Start enjoying your benefits now!</a>
                </p>
            </span>
            <span v-if="formSuccess && !isUserLogged">
                <h1>You're almost done!</h1>
                <p>
                    We've sent a confirmation email to you from the address "{{ this.userName }}". Just click the link within that email, and your registration will be complete! Go on, we'll wait here.
                </p>
            </span>
        </div> -->
        <div class="register-form registerWithPersona" :class="[ step === 1 ? 'noGreyBg' : '']" v-if="!isUserLogged && formEnable">
            <form data-abide novalidate data-validate-on-blur="true" id="registerForm" v-if="formEnable">
                <div data-abide-error class="alert callout" style="display: none;">
                    <p><i class="fi-alert"></i> There are some errors in your form.</p>
                </div>

                <!-- <p class="reqNote"><span class="req">* </span> Required field</p>
                <h3>Log In Information</h3> -->
                <div class="grid-container regLogInFields" v-show="step === 0">
                    <div class="loginLogoMLR"><img src="/getattachment/44adc819-4717-4eca-8187-a98e2e34cdc7/logo-MLR-242x150.png" alt="" class="fr-fic fr-dii logo_MLR"></div>
                    <div class="registerInstructions">
                        <h1>Register for a free MyLotto Rewards<sup>&reg;</sup> Account!</h1>
                        <p>Only members can enjoy the benefits of MyLotto Rewards. Fill out the form below and start earning points today!</p>
                    </div>
                    <div class="grid-x grid-margin-x grid-margin-y">
                        <div class="cell medium-6">
                            <label class="lt-prpl">
                                Email Address *
                                <input type="text" id="email-address" v-model="userName" v-on:blur="checkIfEmailTaken" placeholder="Email Address *" pattern="email" required>
                                <span class="form-error">
                                    Please provide a valid email address
                                </span>
                            </label>
                        </div>
                        <div class="cell medium-6">
                            <label class="lt-prpl">
                                Confirm Email Address *
                                <input type="text" class="preventPaste" onpaste="return false;" placeholder="Confirm Email Address *" pattern="email" v-model="userNameMatch" v-on:blur="checkForVerifyLaunch" data-equalto="email-address" required>
                                <span class="form-error">
                                    Emails do not match
                                </span>
                            </label>
                        </div>
                        <!-- <div class="cell medium-6" v-if="emailTakenError">
                            <span class="form-error emailTakenError">This email is already in use.</span>
                        </div>
                         -->
                        <!-- <div class="cell medium-6">
                            <label> 
                                <input type="password" @input="validatePassword" v-model="password" :class="{'is-invalid-input':passwordError}" id="password" placeholder="Password *" pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$"  required  >
                                <password v-model="password" :strength-meter-only="true"/>

                                <span class="form-error">
                                    Please create your password
                                </span>
                            </label>
                        </div>
                        <div class="cell medium-6">
                            <label>
                                <input type="password" placeholder="Confirm Password *" aria-describedby="passwordHint" data-equalto="password" required>
                                <span class="form-error">
                                    Passwords do not match
                                </span>
                            </label>
                        </div> -->
                        <!-- <p class="help-text" v-if="!passwordError" id="passwordHint">Your password must be at least eight characters long and include an uppercase letter AND a lowercase letter AND a number.</p>
                        <span class="form-error" v-if="passwordError">
                            Your password must be at least eight characters long and include an uppercase letter AND a lowercase letter AND a number.
                        </span> -->
                    </div>
                    <div class="personaInstructions">
                        <p>You are required to verify your identity before creating your MyLotto Rewards account. This helps us prevent unauthorized access to your account and protect your personal information.</p>
                        <h3>What do I need to verify my ID?</h3>
                        <p>You will need these items to verify your ID:</p>
                        <ul>
                            <li>A valid drivers license, state-issued ID or passport</li>
                            <li>Access to a smartphone, tablet or computer with a camera</li>
                        </ul>
                    </div>
                </div>

                <div class="grid-container" v-show="step === 1">
                    <!-- <p>Persona Workflow step</p> -->
                </div>

                <!-- <h3>Contact Information</h3> -->
                <div class="grid-container regContactFields " v-show="step === 2">
                    <h3>Create Your Profile</h3>
                    <p>Congratulations! We have verified your ID. We just need a few more details to create your account.</p>
                    <p>Fields marked with * are required.</p>
                    <hr>
                    <!-- <h4>Username</h4> -->
                    <div class="grid-x grid-margin-x grid-margin-y">
                        <div class="cell large-8">
                            <label class="lt-prpl">Username *
                                <input type="text" v-model="userName" readonly>
                            </label>
                        </div>
                        <div class="cell medium-6">
                            <label class="lt-prpl">
                                First Name *
                                <input type="text" v-model="firstName" placeholder="First Name *"  readonly required>
                                <span class="form-error">
                                    Please enter your first name
                                </span>
                            </label>
                        </div>

                        <div class="cell medium-6">
                            <label class="lt-prpl">
                                Last Name *
                                <input type="text" v-model="lastName" placeholder="Last Name *"  readonly required>
                                <span class="form-error">
                                    Please enter your last name
                                </span>
                            </label>
                        </div>

                        <div class="cell medium-6">
                            <label class="lt-prpl">Date of birth *</label>

                            <datepicker 
                                    placeholder="Select Date" 
                                    name="dateOfBirth"
                                    :format="customFormatter"
                                    v-model="date_birth"
                                    @selected="dateSelected()"
                                    :input-class="{'is_valid': birth_error}"
                                    required
                                    disabled
                                    >
                            </datepicker>

                            <span class="form-error-birth" v-if="birth_error">
                                You must be over 18
                            </span>

                        </div>

                        <fieldset class="cell medium-6">
                            <input required id="ageCheckbox" name="ageCheckbox" type="checkbox" v-model="ageCheck"><label for="ageCheckbox" class="dobLabel lt-prpl">I am 18 years old or older *</label>
                            <span class="form-error" v-if="ageCheckError">
                                Please confirm that you are 18 years or older
                            </span>
                        </fieldset>
                        
                        <div class="cell large-8">
                            <h3>Create a password</h3>
                        </div>

                        <div class="cell medium-6">
                            <label> 
                                <input type="password" @input="validatePassword" v-model="password" :class="{'is-invalid-input':passwordError}" id="password" placeholder="Password *" pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$"  required  >
                                <password v-model="password" :strength-meter-only="true"/>

                                <span class="form-error">
                                    Please create your password
                                </span>
                            </label>
                        </div>
                        <div class="cell medium-6">
                            <label>
                                <input type="password" placeholder="Confirm Password *" aria-describedby="passwordHint" data-equalto="password" required>
                                <span class="form-error">
                                    Passwords do not match
                                </span>
                            </label>
                        </div>
                        <p class="help-text" v-if="!passwordError" id="passwordHint">Your password must be at least eight characters long and include an uppercase letter AND a lowercase letter AND a number.</p>
                        <span class="form-error" v-if="passwordError">
                            Your password must be at least eight characters long and include an uppercase letter AND a lowercase letter AND a number.
                        </span>
                        
                    
                        <div class="cell medium-6">
                            <label class="lt-prpl">
                                Street Address *
                                <input type="text" v-model="mailingAddress" placeholder="Street Address *" required>
                                <span class="form-error">
                                    Please enter your address
                                </span>
                            </label>
                        </div>
                        <div class="cell medium-6">
                            <label class="lt-prpl">
                                Address (continued)
                                <input type="text" v-model="addressContinued" placeholder="Address (continued)">
                            </label>
                        </div>
                        <div class="cell medium-6">
                            <label class="lt-prpl">
                                City *
                                <input type="text" placeholder="City *" v-model="city" required>
                                <span class="form-error">
                                    Please enter your city
                                </span>
                            </label>
                        </div>
                        <div class="cell medium-6">
                            <label class="lt-prpl">
                                State *
                                <select id="stateSelect" v-model="state" required>
                                    <option value="OH">OH</option>
                                    <option value = "AL">AL</option>
                                    <option value = "AK">AK</option>
                                    <option value = "AZ">AZ</option>
                                    <option value = "AR">AR</option>
                                    <option value = "CA">CA</option>
                                    <option value = "CO">CO</option>
                                    <option value = "CT">CT</option>
                                    <option value = "DE">DE</option>
                                    <option value = "FL">FL</option>
                                    <option value = "GA">GA</option>
                                    <option value = "HI">HI</option>
                                    <option value = "ID">ID</option>
                                    <option value = "IL">IL</option>
                                    <option value = "IN">IN</option>
                                    <option value = "IA">IA</option>
                                    <option value = "KS">KS</option>
                                    <option value = "KY">KY</option>
                                    <option value = "LA">LA</option>
                                    <option value = "ME">ME</option>
                                    <option value = "MD">MD</option>
                                    <option value = "MA">MA</option>
                                    <option value = "MI">MI</option>
                                    <option value = "MN">MN</option>
                                    <option value = "MS">MS</option>
                                    <option value = "MO">MO</option>
                                    <option value = "MT">MT</option>
                                    <option value = "NE">NE</option>
                                    <option value = "NV">NV</option>
                                    <option value = "NH">NH</option>
                                    <option value = "NJ">NJ</option>
                                    <option value = "NM">NM</option>
                                    <option value = "NY">NY</option>
                                    <option value = "NC">NC</option>
                                    <option value = "ND">ND</option>
                                    <option value = "OK">OK</option>
                                    <option value = "OR">OR</option>
                                    <option value = "PA">PA</option>
                                    <option value = "RI">RI</option>
                                    <option value = "SC">SC</option>
                                    <option value = "SD">SD</option>
                                    <option value = "TN">TN</option>
                                    <option value = "TX">TX</option>
                                    <option value = "UT">UT</option>
                                    <option value = "VT">VT</option>
                                    <option value = "VA">VA</option>
                                    <option value = "WA">WA</option>
                                    <option value = "DC">DC</option>
                                    <option value = "WV">WV</option>
                                    <option value = "WI">WI</option>
                                    <option value = "WY">WY</option>
                                    <option>Province</option>
                                    <option value = "AB">AB</option>
                                    <option value = "BC">BC</option>
                                    <option value = "MB">MB</option>
                                    <option value = "NB">NB</option>
                                    <option value = "NL">NL</option>
                                    <option value = "NS">NS</option>
                                    <option value = "NT">NT</option>
                                    <option value = "NU">NU</option>
                                    <option value = "ON">ON</option>
                                    <option value = "PE">PE</option>
                                    <option value = "QC">QC</option>
                                    <option value = "SK">SK</option>
                                    <option value = "YT">YT</option>
                                </select>
                                <span class="form-error">
                                    Please enter your state
                                </span>
                            </label>
                        </div>

                        <div class="cell medium-6">
                            <label class="lt-prpl">
                                Zip/Postal Code *
                                <input type="text" minlength="5" maxlength="5" placeholder="Zip/Postal Code *" v-model="zipcode" required pattern="number">
                                <span class="form-error">
                                Please provide a valid Zip/Postal code
                                </span>
                            </label>
                        </div>

                        <div class="cell medium-6">
                            <label class="lt-prpl">
                                Country *
                                <select id="countrySelect" v-model="country">
                                    <option value="USA">United States</option>
                                    <option value="CAN">Canada</option>
                                </select>
                            </label>
                        </div>

                        <div class="cell medium-6">
                            <label class="lt-prpl">
                                Primary Phone Number *
                                <!-- <input type="tel" id="phoneInput" placeholder="Primary Phone Number *" required pattern="^\d{3}-\d{3}-\d{4}$" @input="formatPhone" v-model="phoneInput"> -->
                                <input type="tel" id="phoneInput" placeholder="Primary Phone Number *" required pattern="^\d{3}-\d{3}-\d{4}$" @input="formatPhone">
                                <span class="form-error">
                                Please provide a valid phone number
                                </span>
                            </label>
                        </div>

                        <fieldset class="cell small-12 checkboxRow">
                            <input  id="extraPoints" name="extraPoints" type="checkbox" v-model="optIn"><label for="extraPoints" class="lt-prpl"><b>
                                Don’t miss out on any fun! Get email notifications of new promotions, drawings and chances to earn extra points.
                                </b>
                            </label>
                        </fieldset>  
                    </div>
                </div>

                <h3 v-show="step === 2">Terms of Use</h3>
                <div v-show="step === 2" class="grid-container regDisclaimers">
                    <div class="grid-x grid-margin-x grid-margin-y">
                        <div class="cell small-12">
                            <div class="terms" v-html="termsContent"></div>
                        </div>

                        <fieldset class="cell small-12 checkboxRow">
                            <input required id="agreeTerms" v-model="terms" name="agreeTerms" type="checkbox"><label for="agreeTerms" class="lt-prpl"><b>
                                I have read and agree to the above Ohio Lottery Terms of Use. *
                                </b>
                            </label>
                            <span class="form-error" v-if="tcCheckError">
                                You must agree to the Terms & Conditions before registering.
                            </span>
                        </fieldset> 
                    </div>
                </div>

                <div class="failedKYC" v-if="step === 3">
                    <div>
                        <h1>Unable to verify your ID</h1>
                        <p>I'm sorry, we are unable to verify your ID.</p>
                        <p>Possible reasons for an unsuccessful verification include:</p>
                        <ul>
                            <li>The form of identification already exists in our records</li>
                            <li>The form of identification is expired</li>
                            <li>The details on the form of identification were unclear or blurry</li>
                            <li>There was a problem with your Selfie verification</li>
                        </ul>
                        <p>If you need assistance, please contact MyLotto Rewards customer support:</p>
                        <p><a href="/mylotto-rewards/help">Online Help Center</a></p>
                        <p>Helpline: <a href="tel:+8553197189">(855) 319-7189</a></p>
                        <div>
                            <button type="button" class="button btn-tertiary" @click="kycTryAgain">Try Again</button>
                        </div>
                    </div>
                </div>

                <div class="grid-container regButtons ">
                    <div class="grid-x grid-margin-x grid-margin-y">
                        <fieldset class="cell large-12">
                            <button class="button submit-button" @click="submitCheck" v-if="step === 2">Register</button>
                            <button class="button verify-button" :class="{disabled: !userNameMatchCheck}" :disabled="!userNameMatchCheck" type="button" @click="nextStep" v-if="step === 0">Verify My ID</button>
                        </fieldset>
                    </div>
                </div>
            </form>

            <div class="reveal callout" id="errorAddressModal" data-reveal>
                <span v-if="loadingAddress">
                    <h5>Confirming Address</h5>
                    <p>
                        Please wait while we confirm your address.
                    </p>
                    <div>
                        <button class="button"  @click="editAddress">Cancel</button>
                    </div>
                </span>
                <span v-if="!loadingAddress && confirmSubmit">
                    <h5>Registration in progress</h5>
                    <p>
                        Please wait while we register your user.
                    </p>
                </span>
                <span v-if="!loadingAddress && !confirmSubmit">
                    <h3>Address Confirmation</h3>
                    <p>Please review the verified United States Postal Service address shown below. 
                        Select the Confirm button to proceed with registration using the verified address
                        or select Edit to revise your address.</p>

                    <div class="grid-x">
                        <div class="cell large-6">
                            <h4>Submitted Address</h4>
                            <p>{{ this.mailingAddress }} <br>
                            {{ this.city }}  <br>
                            {{ this.state }}  <br>
                            {{ this.zipcode }}  </p>

                            <div>
                                <button class="button" value="Edit" @click="editAddress">Edit</button>
                            </div>
                        </div>
                        <div class="cell large-6" v-if="!verifiedAddress">
                            <h4>Verified USPS Address</h4>
                            <p>We could not verify the address supplied. 
                                Please review it for accuracy and make any necessary changes before continuing with registration.
                            </p>
                        </div>

                        <div class="cell large-6" v-if="verifiedAddress">
                            <h4>Verified USPS Address</h4>
                            <p>{{ this.verifiedMailingAddress }} <br>
                            {{ this.verifiedCity }}  <br>
                            {{ this.verifiedState }}  <br>
                            {{ this.verifiedZipcode }}  </p>

                            <div>
                                <button class="button alert" value="Confirm" @click="confirmAddress">Confirm</button>
                            </div>
                        </div>
                    </div>
                    <p>If you have questions or are having trouble registering, please <a href="/mylotto-rewards/help/mylotto-rewards-contact">contact us.</a></p>
                </span>
            </div>

            <div class="reveal alert callout errorRegisterModal" id="errorRegisterModal" data-reveal>
                <i class="fi-alert"></i> Error: <span id="errorInput">{{registerErrorMsg}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import Datepicker from "vue-moment-datepicker";
    import $ from 'jquery';
    import Password from 'vue-password-strength-meter'
    import moment from 'moment';

    export default {
        components: {
           Datepicker,
           Password
        },
        data: function () {
            return {
                isUserLogged: this.isUserAuthed(),
                userName: '',
                password: null,
                firstName: '',
                lastName: '',
                date_birth: '',
                birth_error: false,
                mailingAddress: '',
                addressContinued: '',
                city: '',
                state: 'OH',
                zipcode: '',
                country: 'USA',
                termsTitle: '',
                termsContent: '',
                terms: false,
                registerApiToken: '',
                registerErrorMsg: '',
                formEnable: true,
                formSuccess: false,
                verifiedAddress: false,
                verifiedMailingAddress: '',
                verifiedCity: '',
                verifiedState: '',
                verifiedZipcode: '',
                confirmBodyAddress: {},
                phoneInput: '',
                RegistrationType: 0,
                optIn: false,
                loadingAddress: true,
                addressCancel: true,
                rules: '',
                passwordValid: false,
                passwordError: false,
                test: '',
                confirmSubmit: false,
                ageCheck: false,
                ageCheckError: false,
                tcCheckError: false,
                addressError: false,
                userNameMatch: '',
                userNameMatchCheck: false,
                step: 0,
                minimumAppVersion: '',
                summary: '',
                transactionID: ''
            }
        },
        mounted: function () {
            $(document).foundation();
            this.getToken();
            // this.phoneNumberFormat(this.phoneInput);   
            this.getPasswordRules();
        },
        methods: {
            getPasswordRules() {
                var apiToken = this.token,
                    url = process.env.VUE_APP_APIURL + '/1.0/PAM/GetPasswordRules';

                return fetch(url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + apiToken,
                        },
                    }
                )
                .then((response) => response.json())
                .then((json) => {
                    this.rules = json.data;
                    this.test = new RegExp(this.rules.regularExpression);
                })
                .catch((error) => {
                    // console.error("There was an error!", error);
                    return (this.errorMessage = error);
                });
            },
            validatePassword() {
                let minLength = this.rules.minimalLength,
                    password = this.password,
                    // test = new RegExp(this.rules.regularExpression),
                    test = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/),
                    validate = test.test(password);

                    // console.log(test)

                if (validate && password.length >= minLength) {
                    this.passwordValid = true;
                } else {
                    this.passwordValid = false;
                }

                // console.log(this.passwordValid)
            },
            customFormatter(date) {
                return moment(date).format('MM/DD/yyyy');
            },
            getToken() {
                // then hit API with token
                this.getAPItoken()
                .then((token) => {
                    this.fetchData(token);
                }).then((token) => {
                    this.setAppVersion(token);
                });

            },
            fetchData(token) {
                var apiToken = token;
                this.registerApiToken = apiToken;
                var urlProtocol = this.getProtocol();
                
                return fetch(urlProtocol+process.env.VUE_APP_APIURL+"/1.0/Games/StaticContentElements/StaticContentElement/GetContentElementByKey?contentKey=mlr-terms-conditions", {
                    headers: {
                        'Authorization': 'Bearer '+apiToken,
                    },
                })
                .then(response => response.json())
                .then(json => {
                    var terms = json.data;
                    // console.log(terms)
                    this.termsTitle = terms[0].title;
                    this.termsContent = terms[0].centralContent;
                    
                })
                .catch(error => {
                    // console.error("There was an error!", error);
                    return this.errorMessage = error;
                });
            },
            dateSelected () {
                this.$nextTick(function(){
                    function getAge(birth, target) {
                        let months = target.diff(birth, 'months', true)
                        let birthSpan = {year: Math.floor(months/12), month: Math.floor(months)%12, day: Math.round((months%1)*target.daysInMonth(),0)}
                        if (birthSpan.year < 1 && birthSpan.month < 1) {
                            return 10
                        } else if (birthSpan.year < 1) {
                            return 10
                        } else if (birthSpan.year < 2) {
                            return 10
                        } else {
                            return birthSpan.year
                        }
                    
                    }
                    let birth = moment(this.date_birth)
                    let ageOfPerson = getAge(birth, moment());
                    if (ageOfPerson < 18 ){
                        this.birth_error = true
                    }else{
                        this.birth_error = false;
                    }
                });
                      
            },
            submitCheck: function() {
                this.addressError = false;
                this.ageCheck === false ? this.ageCheckError = true : this.ageCheckError = false;
                this.terms === false ? this.tcCheckError = true : this.tcCheckError = false;
                if (this.passwordValid === true) {
                    this.passwordError = false;
                    let _this = this;
                            
                    $(document).off('formvalid.zf.abide');
                    // script to trigger other events 
                    $(document).on("formvalid.zf.abide", function() {
                        var apiToken = _this.registerApiToken; 

                        let bodyAddress = {
                            "address": {
                                "address": _this.mailingAddress,
                                "address2": _this.addressContinued,
                                "city": _this.city,
                                "state": _this.state,
                                "zipCode": _this.zipcode
                            },
                            "customerId": 0
                        }

                        
                        const requestAddressOptions = {
                            method: 'POST',
                            headers: { 
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer '+apiToken
                            },
                            body: JSON.stringify(bodyAddress)
                        };

                        // let bodyRegister = {
                        //     "userId": 0,
                        //     "email": _this.userName,
                        //     "firstName": _this.firstName,
                        //     "lastName": _this.lastName,
                        //     "address": bodyAddress.address,
                        //     "validateAddress": true
                        // }

                        let bodyRegister = {
                            "userId": 0,
                            "username": _this.userName, //added
                            "email": _this.userName,
                            "password": _this.password, //added
                            "firstName": _this.firstName,
                            "lastName": _this.lastName,
                            "address": bodyAddress.address,
                            // "validateAddress": true
                            "phoneNumber": $('#phoneInput').val(),
                            "termsAccepted": _this.terms,
                            "signupOptIn": _this.optIn,
                            "registrationType": 0,
                            "appVersion": _this.minimumAppVersion,
                            "summary": _this.summary,
                            "transactionID": _this.transactionID
                        }

                        const requestRegisterOptions = {
                            method: 'POST',
                            headers: { 
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer '+apiToken
                            },
                            body: JSON.stringify(bodyRegister)
                        };

                        $('#errorAddressModal').foundation('open');                    

                        fetch("https:"+process.env.VUE_APP_APIURL+"/1.0/PAM/ValidateAddress", requestAddressOptions)
                            .then(response => response.json())
                            .then(data => {
                                console.log('break here')
                                _this.addressCancel ? _this.loadingAddress = true : _this.loadingAddress = false;
                                if( data.data.isProvidedAddressAvailable && data.data.isSuggestedAddressAvailable ){

                                    if( bodyAddress.address.address2 === '' ){
                                        bodyAddress.address.address2 = null
                                    }

                                    let newAddress = bodyAddress.address;
                                    let returnAddress = data.data.address;

                                    if( JSON.stringify(newAddress) === JSON.stringify(returnAddress)  ){
                                        // console.log( 'are same objects')

                                        newAddress = returnAddress;

                                        fetch("https:"+process.env.VUE_APP_APIURL+"/1.0/PAM/RegisterUser", requestRegisterOptions)
                                        .then(response => response.json())
                                        .then(dataRegister => {
                                            console.log("dataRegister below");
                                            console.log(dataRegister)
                                            console.log(typeof dataRegister.data);
                                            $('#errorAddressModal').foundation('close');
                                            if ( dataRegister.data === null) {
                                                //process 
                                                var processedError = _this.processError(dataRegister.error.message);
                                                _this.registerErrorMsg = processedError;
                                                // $('#errorInput').html(_this.registerErrorMsg);
                                                // let input = document.getElementById('errorInput');
                                                // input.value = _this.registerErrorMsg;
                                            setTimeout(() => {
                                                let popup = new Foundation.Reveal($('#errorRegisterModal'));
                                                popup.open();
                                            }, 500)
                                            this.formEnable = true;
                                            this.confirmSubmit = false;
                                            } else {
                                                _this.formEnable = false;
                                                _this.formSuccess = true;
                                                _this.loadingAddress = false;
                                            }

                                            

                                        });
                                    }else{
                                        _this.verifiedAddress = true;
                                        _this.confirmBodyAddress = returnAddress;
                                        _this.verifiedMailingAddress = returnAddress.address;
                                        _this.verifiedCity = returnAddress.city;
                                        _this.verifiedState = returnAddress.state;
                                        _this.verifiedZipcode = returnAddress.zipCode;
                                        _this.loadingAddress = false;
                                    }

                                    
                                }else{
                                    _this.addressError = true;
                                    _this.loadingAddress = false;
                                }

                            });
                    })
                    // to prevent form from submitting upon successful validation
                    .on("submit", function(ev) {
                        ev.preventDefault();
                    });
                } else {
                    // this.registerErrorMsg = data.error.message;
                    this.passwordError = true;
                }
            },
            editAddress: function(){
                $('#errorAddressModal').foundation('close');
                this.loadingAddress = true;
            },
            confirmAddress: function(){
                // console.log( this.confirmBodyAddress  )
                let _this = this;
                var apiToken = this.registerApiToken;

                let phone = $('#phoneInput').val();

                phone = phone.replace(/[-]/g, "");

                let confirmBodyRegister = {
                    "userName": this.userName,
                    "email": this.userName,
                    "password": this.password,
                    "firstName": this.firstName,
                    "lastName": this.lastName,
                    "address": this.confirmBodyAddress,
                    // "npiPlayerID": "string",
                    // "phoneNumber": this.phoneNumber,
                    "phoneNumber": phone,
                    "birthdate": this.date_birth,
                    "termsAcceptance": this.terms,
                    "signupOptIn": this.optIn, 
                    "registrationType": 0,
                    "appVersion": this.minimumAppVersion,
                    "summary": this.summary,
                    "transactionID": this.transactionID
                }

                const confirmRegisterOptions = {
                    method: 'POST',
                    headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+apiToken
                    },
                    body: JSON.stringify(confirmBodyRegister)
                };

                this.formEnable = false;
                this.confirmSubmit = true;

                console.log("Below is confirmRegisterOptions");
                console.log(confirmRegisterOptions);
                // alert("Break to grab ");

                fetch("https:"+process.env.VUE_APP_APIURL+"/1.0/PAM/RegisterUser", confirmRegisterOptions)
                .then(response => response.json())
                .then(dataRegister => {
                    console.log(dataRegister)
                    $('#errorAddressModal').foundation('close');
                    if( dataRegister.data == null ){
                        $('#errorInput').html(_this.registerErrorMsg);
                        let input = document.getElementById('errorInput');
                        input.value = _this.registerErrorMsg;
                        
                        _this.registerErrorMsg = dataRegister.error.message;
                        setTimeout(() => {
                            let popup = new Foundation.Reveal($('#errorRegisterModal'));
                            popup.open();
                        }, 500)
                        this.formEnable = true;
                        this.confirmSubmit = false;
                    }else{
                        this.formEnable = false;
                        this.formSuccess = true;
                        this.loadingAddress = false;
                    }
                });
            },
            formatPhone() {
                $('#phoneInput').value = this.phoneNumberFormat(document.getElementById('phoneInput'));
            },
            nextStep: function() {
                this.step = this.step + 1;

                if(this.step === 1) {
                    this.launchPersonaEmbed(this.userName, this.handlePersonaData);
                }
            },
            checkIfEmailTaken() {
                let emailToCheck = this.userName;

                if (emailToCheck === '') {
                    this.checkForVerifyLaunch();
                    return;
                }


                let url = process.env.VUE_APP_APIURL + '/1.0/PAM/EmailTaken/?email=' + emailToCheck;
                var apiToken = this.registerApiToken;
                console.log(apiToken);

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        'Authorization': "Bearer " + apiToken
                    }
                })
                .then((response) => response.json())
                .then((json) => {
                    console.log(json);

                    if (json.data) {
                        this.userName = '';
                        alert("This email address is already in use.");
                    } 
                })
                .then(() => {
                    this.checkForVerifyLaunch();
                })
                .catch((error) => {
                    console.log(error);
                })

            },
            checkForVerifyLaunch() {
                if (this.userName === this.userNameMatch && this.userName !== '') {
                    this.userNameMatchCheck = true;
                } else {
                    this.userNameMatchCheck = false;
                }
            },
            handlePersonaData(personaData, inqId, status, email) {
                this.step = 2;
                console.log(this.step);
                console.log('In handle');
                console.log(personaData);

                if (status === 'completed' || status === 'approved' || status === 'passed') {
                    //prepopulate the fields
                    this.userName = email;
                    this.firstName = personaData["name-first"].value;
                    this.lastName = personaData["name-last"].value;

                    //process dob
                    this.date_birth = this.processDOB(personaData["birthdate"].value);

                    //process zip
                    this.zipcode = this.processZip(personaData["address-postal-code"].value);

                    this.mailingAddress = personaData["address-street-1"].value;
                    this.addressContinued = personaData["address-street-2"].value;
                    this.city = personaData["address-city"].value;
                    this.state = personaData["address-subdivision"].value;
                    this.summary = JSON.stringify(personaData);
                    this.transactionID = inqId;
                    // this.country = personaData["address-country-code"].value;
                    //need to handle this better for country code
                    if (personaData["address-country-code"].value === "US") {
                        this.country = "USA";
                    }
                }

                if (status === 'failed' || status === 'declined') {
                    this.step = 3;
                }


            },
            setAppVersion(token) {
                let url = window.location.origin + '/registration/GetMinimumAppVersion';

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json-patch+json'
                    }
                })
                .then((response) => response.json())
                .then((json) => {
                    console.log('Registration Minimum App Version Data');
                    this.minimumAppVersion = json.minAppVersionNumber;
                })
                .catch((error) => {
                    return (this.errorMessage = error)
                })
            },
            kycTryAgain() {
                window.location.reload();
            },
            processError(err) {
                var newError;
                switch(err) {
                    case 'Invalid User':
                        newError = "There was a problem with registration. Please try again later.";
                    break;
                    default:
                        newError = "There was an error. Please try again later."

                }
                return newError;
            },
            processDOB(dob) {
                var newDOB = dob.replace(/-/g, '/');
                return newDOB;
            },
            processZip(zip) {
                var newZip,
                    dashLocation = zip.indexOf('-');

                    if (dashLocation !== -1) {
                        newZip = zip.slice(0, dashLocation);
                        console.log(newZip);
                    }


                    return newZip;
                
            }
        },
        computed: {
           
        },
    };
</script>

<style lang="scss" scoped>
// input, select{
//     height: auto;
//     line-height: inherit;
// }
h3 {
    margin-bottom: 2rem;
}
.form-error-birth{
    margin-top: -0.5rem;
    margin-bottom: 1rem;
    font-size: 0.75rem;
    font-weight: bold;
    color: #cc4b37;
    display: block;
}

.terms{
    overflow: auto;
    height: 200px;
    width: 100%;
    padding: 10px;
    margin: 0 0 10px;
    background: #fff;
    border: 1px solid #B9BDC3;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background-clip: padding-box;
}
.reqNote {
    float: right;
}
.submit-button {
    margin: 0 auto;
}

.checkboxRow {
    display: flex;
    align-items: baseline;
}
/* register with Persona */
.register-form {
    &.registerWithPersona {

    &.noGreyBg {
        background-color: transparent;
    }
        .logo_MLR {
            width: 50%;
            max-width: 242px;
            float: unset;
            display: block;
            margin: 0 auto;
        }

        h1 {
            font-size: 2.4rem;
        }

        p {
            font-size: 1.6rem;
        }

        h3 {
            font-size: 2rem;
            margin-bottom: 0.5em;
        }

        .button.verify-button {
            margin: 0 auto 0.8em;
        }

        .regContactFields {
            h3 {
                font-size: 2.4rem;
            }
            p#passwordHint {
                font-size: 1.4rem;
            }
        }

        .terms p {
            font-size: 1.6rem;
        }
    }
}
</style>