<template>
    <div class="eAppModule">
        <div class="moduleContent cf reviewBusiness appReview cf">
            <h4>Business Name</h4>
            <dl class="reviewFields cf">
                <dt>Legal Business Name</dt>
                <dd>{{ businessInformation.businessLegalName }}</dd>
                <dt>DBA</dt>
                <dd>{{ businessInformation.businessDoingBusinessAs }}</dd>
                <dt>Business Phone</dt>
                <dd>{{ businessInformation.businessPhoneNumber }}</dd>
                <dt>Alternate Phone</dt>
                <dd>{{ businessInformation.businessPhoneNumberSecondary }}</dd>
                <dt>Business Fax</dt>
                <dd>{{ businessInformation.businessFaxNumber }}</dd>
            </dl>

            <h4>Business Address</h4>
            <dl class="reviewFields cf">
                <dt>Business Address</dt>
                <dd>
                    {{ businessInformation.businessStreetAddress }}<br>
                    {{ businessInformation.businessCity }},
                    {{ businessInformation.businessState }},
                    {{ businessInformation.businessZip }}-{{ businessInformation.businessZipPlus4 }}<br>
                    {{ businessInformation.businessCounty }}<br>
                    <a :href="'https://www.google.com/maps/place/' + businessInformation.businessStreetAddress + ',+' + businessInformation.businessCity + ',+' + businessInformation.businessState + '+' + businessInformation.businessZip"
                        target="_blank">View on map</a>
                </dd>
                <dt>Mailing Address</dt>
                <dd>
                    <span v-if="businessInformation.mailingSameAsBusiness">Same as business address</span>
                    <span class="mailingAddress" v-else>
                        {{ businessInformation.mailingStreetAddress }}<br>
                        {{ businessInformation.mailingCity }},
                        {{ businessInformation.mailingState }},
                        {{ businessInformation.mailingZip }}<span v-if="businessInformation.mailingZipPlus4">-{{
                            businessInformation.mailingZipPlus4 }}</span>
                    </span>
                </dd>
                <dt v-if="application.applicationDistrict !== ''">District</dt>
                <dd v-if="application.applicationDistrict !== ''">{{ application.applicationDistrict }}</dd>
                <dt v-if="application.applicationRegion !== ''">Region</dt>
                <dd v-if="application.applicationRegion !== ''">{{ application.applicationRegion }}</dd>
            </dl>

        </div>
        <div class="moduleContent cf reviewContact appReview">

            <h4>Contact Information</h4>
            <dl class="reviewFields cf">
                <dt>Primary Contact</dt>
                <dd>{{ contactInformation != null ? contactInformation.primaryContactName : '' }}</dd>
                <dt>Title</dt>
                <dd>{{ contactInformation != null ? contactInformation.primaryContactTitle : '' }}</dd>
                <dt>E-mail Address</dt>
                <dd>{{ contactInformation != null ? contactInformation.primaryContactEmail : '' }}</dd>
                <dt>Phone</dt>
                <dd>{{ contactInformation != null ? contactInformation.primaryContactPhone : '' }}</dd>
            </dl>

            <dl class="reviewFields cf" style="display:none">
                <dt>Secondary Contact</dt>
                <dd>{{ contactInformation != null ? contactInformation.secondaryContactName : '' }}</dd>
                <dt>Title</dt>
                <dd>{{ contactInformation != null ? contactInformation.secondaryContactTitle : '' }}</dd>
                <dt>E-mail Address</dt>
                <dd>{{ contactInformation != null ? contactInformation.secondaryContactEmail : '' }}</dd>
                <dt>Phone</dt>
                <dd>{{ contactInformation != null ? contactInformation.secondaryContactPhone : '' }}</dd>
            </dl>

        </div>
        <div v-if="showPrincipals" class="moduleContent reviewPrincipal appReview">
            <h4>Principals</h4>

            <dl v-for="(principal, index) in principalsList" :key="index" class="reviewFields cf">
                <dt>Name</dt>
                <dd>{{ principal.principalFirstName }} {{ principal.principalLastName }}</dd>
                <dt>Home Address</dt>
                <dd>
                    {{ principal.principalStreetAddress }}
                    <br>
                    {{ principal.principalCity }},
                    {{ principal.principalState }},
                    {{ principal.principalZip }}<span v-if="principal.principalZipPlus4">-{{ principal.principalZipPlus4
                    }}</span>
                </dd>
                <dt>E-mail Address</dt>
                <dd>{{ principal.principalEmail }} </dd>
                <dt>Home Phone</dt>
                <dd>{{ principal.principalHomePhoneNumber }} </dd>
                <dt>Mobile Phone</dt>
                <dd>{{ principal.principalCellPhoneNumber }} </dd>
                <dt>Date of Birth</dt>
                <dd>{{ principal.principalDateOfBirth }} </dd>
                <dt>Social Security Number</dt>
                <dd>{{ principal.principalSSN }} </dd>
                <dt>Percent Ownership</dt>
                <dd>{{ principal.principalPercentOwnership }} %</dd>
            </dl>

        </div>
        <div v-if="!isLoading" class="moduleContent cf reviewHours appReview">


            <h4>Business Hours</h4>
            <dl class="reviewFields cf">
                <dt>Monday</dt>
                <dd>{{ BusinessHours1 }}</dd>
                <dt>Tuesday</dt>
                <dd>{{ BusinessHours2 }}</dd>
                <dt>Wednesday</dt>
                <dd>{{ BusinessHours3 }}</dd>
                <dt>Thursday</dt>
                <dd>{{ BusinessHours4 }}</dd>
                <dt>Friday</dt>
                <dd>{{ BusinessHours5 }}</dd>
                <dt>Saturday</dt>
                <dd>{{ BusinessHours6 }}</dd>
                <dt>Sunday</dt>
                <dd>{{ BusinessHours7 }}</dd>
            </dl>

        </div>

        <div class="appReviewNotesSOB cf" v-if="showSOBnotes === true">
            <div class="moduleContent reviewSOB appReview cf">
                <h4>Sale of Business Information</h4>
                <div v-if="showSOBnotes === true">
                    <label for="sobCheck" id="sobCheckLabel"><strong>Sale of Business</strong></label>
                    <input type="checkbox" id="sobCheck" v-model="showSOBform" @change="handleSOB">
                    <span class="sobNote disclaimer" v-if="showSOBform === false">Checking this box will convert this application to a Sale of Business and display the sale of business information, which <em>must</em> be completed before moving on.</span>
                    <span class="sobNote disclaimer" v-if="showSOBform === true">Unchecking this box will remove the Sale of Business flag for this application.</span>
                </div>
                <dl class="reviewFields cf" v-if="showSOBform">
                    <dt>Business Buyer Name</dt>
                    <dd><input v-model="businessInformation.businessBuyerName" name="businessBuyerName_txtText" type="text"
                    id="businessBuyerName_txtText"> </dd>
                    <dt>Business Owner Name</dt>
                    <dd><input v-model="businessInformation.businessOwnerName" name="businessOwnerName_txtText" type="text"
                    id="businessOwnerName_txtText"></dd>
                    <!-- <dt>Business Original Owner</dt>
                    <dd>{{ businessInformation.businessOriginalOwner }}</dd> -->
                    <dt>Business Owner Location</dt>
                    <dd><input v-model="businessInformation.businessOwnerLocation" name="businessOwnerLocation_txtText" type="text"
                    id="businessOwnerLocation_txtText"></dd>
                    <dt>Business Owner Agent Number</dt>
                    <dd><input v-model="businessInformation.businessOwnerAgentNumber" name="businessOwnerAgentNumber_txtText" type="text"
                    id="businessOwnerAgentNumber_txtText"></dd>
                    <dt>Business Transfer Date</dt>
                    <dd>
                        <datepicker
                            class="check-datepicker"
                            name="businessTransferDate_txtText"
                            :format="customFormatter"
                            v-model="businessInformation.businessTransferDate"
                            :disabledDates="disabledDates"
                            >
                        </datepicker>
                    </dd>
                    <dt>Business Signup Date</dt>
                    <dd>
                        <datepicker
                            class="check-datepicker"
                            name="businessSignupDate_txtText"
                            :format="customFormatter"
                            v-model="businessInformation.businessSignupDate"
                            :disabledDates="disabledDates"
                            >
                        </datepicker>
                    </dd>
                    <dt>Pending Sale of Business Form</dt>
                    <dd>
                        <div v-if="showSOBnotes">
                            <!-- form for eApp-RegionalManager & eAppDistrictRep -->
                            <!-- set up File upload here -->
                            <VueFileAgent ref="vueFileAgent" :multiple="false" :deletable="true" :meta="true"
                                :linkable="true" :accept="'.pdf,.doc,.docx,.ppt,.pptx,.gif,.jpg,.jpeg,.png'" :theme="'list'"
                                :maxSize="'5MB'" :maxFiles="1" :helpText="'Choose file'" :errorText="{
                                    type: 'Invalid file type. Allowed extensions: pdf, doc, docx, ppt, pptx, gif, jpg, jpeg, png',
                                    size: 'Files should not exceed 5mb in size',
                                }" @select="handleFile($event)" @beforedelete="onBeforeDelete($event)"
                                @delete="onDelete($event)">
                            </VueFileAgent>
                        </div>
                        <p v-if="fileSOBForm">
                            Download Form: <a :href="fileSOBForm" :download="application.businessInformation.pendingSOBformFileName"
                            target="_blank"><span class="icon icon-arrow-down-line"> </span> {{  application.businessInformation.pendingSOBformFileName }}</a>
                        </p>
                    </dd>
                </dl>
            </div>
        </div>

        <!-- general business info notes -->
        <div v-if="(userRole == 'eAppDistrictRep' || userRole == 'eApp-CentralLicensingManager' || userRole == 'eApp-CentralSalesManager')"
            class="moduleContent reviewNotes appReview cf">
            <h3>Notes</h3>
            <textarea v-model="businessInformation.adminNotes" class="reviewNotes "></textarea>
        </div>

        <div v-if="userRole == 'eApp-ChainSalesRep' || userRole == 'eApp-RegionalManager'" class="btn-actions">
            <!-- empty div if you are Sale Chain represtantive-->
        </div>

        <div v-else class="btn-actions">
            <input v-if="showSaveButton" type="button" name="" @click="onNextAdminClick()" value="Save Notes" class=" btn-primary btnNext"
                title="Save the document and proceed to next page">
        </div>
    </div>
</template>


<script>
import Datepicker from "vue-moment-datepicker";
import moment from 'moment'
export default {

    components: {
        Datepicker
    },
    props: {
        applicationJson: String,
        userId: Number,
        userRole: String
    },
    data: function () {
        return {
            application: {},
            businessInformation: {},
            contactInformation: {},
            dateOfBirth: '',
            showPrincipals: true,
            principalsList: [],
            principalJson: '',
            submitting: false,
            eappId: '',
            showSOBnotes: false,
            showSOBform: false,
            showSaveButton: true,
            isLoading: true,
            disabledDates: {
                to: new Date()
            },
            fileSOBForm: null
        }
    },
    mounted: function () {
        this.isLoading = true;
        this.init();
    },
    methods: {
        init() {
            // check if app has SOB
            // if ('if app has SOB') {
            //     this.showSOBform = true;
            // }
            this.deserializeJson();
            this.getFileSOBForm();
            this.isLoading = false;
        },
        deserializeJson() {
            this.application = JSON.parse(this.applicationJson);
            this.businessInformation = this.application.businessInformation;
            this.contactInformation = this.application.contactInformation;
            let principals = [...this.application.principals];

            for (let i = 0; i < this.application.principals.length; i++) {
                let principal = principals[i],
                    dateOfBirth = moment(principal.principalDateOfBirth).format('L');

                // console.log(principal)

                principal.principalDateOfBirth = dateOfBirth;
            }

            this.principalsList = principals;

            // hide principals for Chain Locations
            if (this.application.applicationChainIsFranchise && !this.application.applicationIsMasterChain && this.application.applicationIspartOfChain) {
                this.showPrincipals = false;
            }

            // only show SOB notes to these roles
            if (this.userRole == 'eAppDistrictRep' || this.userRole == 'eApp-RegionalManager' || this.userRole == 'eApp-CentralLicensingManager') {
                this.showSOBnotes = true;
            }
            this.showSOBform = this.application.businessInformation.isBusinessBeingTransfer;

            if (this.userRole == 'eApp-EFTReviewer' || this.userRole == 'eApp-Security') {
                this.showSaveButton = false;
            }
        },
        onNextAdminClick() {
            // console.log('on next admin click');
            // console.log(this.application.businessInformation.adminNotes);
            this.$emit('onNextAdminClick', { application: this.application, step: 'business', updates: true });
        },
        handleFile(event) {
            // restrict to: pdf,doc,docx,gif,jpg,jpeg,png
            var _this = this;
            var file = event[0].file;
            var fileName = event[0].file.name;
            //var fileType = event[0].file.type

            const reader = new FileReader();
            reader.onload = function (e) {
                _this.application.businessInformation.pendingSOBform = e.target.result.split(',')[1];
                _this.application.businessInformation.pendingSOBformFileName = fileName;
                //_this.application.businessInformation.pendingSOBformFileType = fileType;
            };

            reader.readAsDataURL(file);
        },
        customFormatter(date) {
            return moment(date).format('MM/DD/yyyy');
        },
        onBeforeDelete(event) {
            // optionally, you can confirm and call the deleteFileRecord method
            this.$refs.vueFileAgent.deleteFileRecord(event);
        },
        onDelete(event) {
            this.application.businessInformation.pendingSOBform = null;
            // this.application.tax.attachedFileExtension = null;
            // this.application.tax.attachedFileName = null;
        },
        handleSOB() {
            let isSOB = this.showSOBform;

            !this.showSOBform;

            this.application.businessInformation.isBusinessBeingTransfer = this.showSOBform;
            this.application.businessInformation.saleOfBusiness = this.showSOBform;
        },
        getFileSOBForm() {
            let fileName = this.application.businessInformation.pendingSOBformFileName;
            let fileForm = this.application.businessInformation.pendingSOBform;
            let contentType,
                link;
            const types = {
                'jpg': 'image/jpeg',
                'jpeg': 'image/jpeg',
                'png': 'image/png',
                'gif': 'image/gif',
                'pdf': 'application/pdf',
                'zip': 'application/zip',
                'doc': 'application/msword',
                'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'ppt': 'application/vnd.ms-powerpoint',
                'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation'

            };

            if (fileName != '' && fileName != null) {
                const extension = fileName.split('.').pop().toLowerCase();
                contentType = types[extension] || 'application/octet-stream';
                link = "data:" + contentType + ';base64,' + fileForm;
                this.fileSOBForm = link
            }
            // data:image/jpeg;base64,/9j/4QA


        }

    },
    computed: {
        BusinessHours1() {
            if (this.businessInformation != null) {
                if (this.businessInformation.businessHours1 != null) {
                    let businessHours1 = JSON.parse(this.businessInformation.businessHours1);
                    if (businessHours1.Open24hours) {
                        return 'Open 24 Hours';
                    }
                    else if (businessHours1.Closed) {
                        return 'Closed';
                    }
                    else {
                        let openTime = moment(businessHours1.OpenHour, "HHmm").format("h:mm A");
                        let closeTime = moment(businessHours1.CloseHour, "HHmm").format("h:mm A");
                        return openTime + ' - ' + closeTime;
                    }
                }
            }
            return '';
        },
        BusinessHours2() {
            if (this.businessInformation != null) {
                if (this.businessInformation.businessHours2 != null) {
                    let businessHours = JSON.parse(this.businessInformation.businessHours2);
                    if (businessHours.Open24hours) {
                        return 'Open 24 Hours';
                    }
                    else if (businessHours.Closed) {
                        return 'Closed';
                    }
                    else {
                        let openTime = moment(businessHours.OpenHour, "HHmm").format("h:mm A");
                        let closeTime = moment(businessHours.CloseHour, "HHmm").format("h:mm A");
                        return openTime + ' - ' + closeTime;
                    }
                }
            }
            return '';
        },
        BusinessHours3() {
            if (this.businessInformation != null) {
                if (this.businessInformation.businessHours3 != null) {
                    let businessHours = JSON.parse(this.businessInformation.businessHours3);
                    if (businessHours.Open24hours) {
                        return 'Open 24 Hours';
                    }
                    else if (businessHours.Closed) {
                        return 'Closed';
                    }
                    else {
                        let openTime = moment(businessHours.OpenHour, "HHmm").format("h:mm A");
                        let closeTime = moment(businessHours.CloseHour, "HHmm").format("h:mm A");
                        return openTime + ' - ' + closeTime;
                    }
                }
            }
            return '';
        },
        BusinessHours4() {
            if (this.businessInformation != null) {
                if (this.businessInformation.businessHours4 != null) {
                    let businessHours = JSON.parse(this.businessInformation.businessHours4);
                    if (businessHours.Open24hours) {
                        return 'Open 24 Hours';
                    }
                    else if (businessHours.Closed) {
                        return 'Closed';
                    }
                    else {
                        let openTime = moment(businessHours.OpenHour, "HHmm").format("h:mm A");
                        let closeTime = moment(businessHours.CloseHour, "HHmm").format("h:mm A");
                        return openTime + ' - ' + closeTime;
                    }
                }
            }
            return '';
        },
        BusinessHours5() {
            if (this.businessInformation != null) {
                if (this.businessInformation.businessHours5 != null) {
                    let businessHours = JSON.parse(this.businessInformation.businessHours5);
                    if (businessHours.Open24hours) {
                        return 'Open 24 Hours';
                    }
                    else if (businessHours.Closed) {
                        return 'Closed';
                    }
                    else {
                        let openTime = moment(businessHours.OpenHour, "HHmm").format("h:mm A");
                        let closeTime = moment(businessHours.CloseHour, "HHmm").format("h:mm A");
                        return openTime + ' - ' + closeTime;
                    }
                }
            }
            return '';
        },
        BusinessHours6() {
            if (this.businessInformation != null) {
                if (this.businessInformation.businessHours6 != null) {
                    let businessHours = JSON.parse(this.businessInformation.businessHours6);
                    if (businessHours.Open24hours) {
                        return 'Open 24 Hours';
                    }
                    else if (businessHours.Closed) {
                        return 'Closed';
                    }
                    else {
                        let openTime = moment(businessHours.OpenHour, "HHmm").format("h:mm A");
                        let closeTime = moment(businessHours.CloseHour, "HHmm").format("h:mm A");
                        return openTime + ' - ' + closeTime;
                    }
                }
            }
            return '';
        },
        BusinessHours7() {
            if (this.businessInformation != null) {
                if (this.businessInformation.businessHours7 != null) {
                    let businessHours = JSON.parse(this.businessInformation.businessHours7);
                    if (businessHours.Open24hours) {
                        return 'Open 24 Hours';
                    }
                    else if (businessHours.Closed) {
                        return 'Closed';
                    }
                    else {
                        let openTime = moment(businessHours.OpenHour, "HHmm").format("h:mm A");
                        let closeTime = moment(businessHours.CloseHour, "HHmm").format("h:mm A");
                        return openTime + ' - ' + closeTime;
                    }
                }
            }
            return '';
        }

    }
};
</script>

<style lang="scss" scoped>
    #sobCheckLabel {
        display: inline-block;
        margin-right: 10px;
    }
    .sobNote {
        margin-left: 10px;
        font-size: 1.2rem;
    }
</style>
